const Copyright = () => {
  return (
    <div className="copyright text-white">
      <a
        className="mr-2"
        href="https://privacy.wmg.com/3EE/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </a>
      <span className="mr-2">|</span>
      <a
        className="mr-2"
        href="https://www.atlanticrecords.com/terms-of-use"
        target="_blank"
      >
        Terms of Use
      </a>
      <span className="mr-2">|</span>
      <a
        className="mr-2"
        href="https://www.wminewmedia.com/cookies-policy/?ot=7fda66bb-299e-4a2c-8f75-21b6b604dc92&url=bigbaglegend.com"
        target="_blank"
      >
        Cookies Policy
      </a>
      <span className="mr-2">|</span>
      <a className="ot-sdk-show-settings">
        Do Not Sell My Personal Information
      </a>
      <div>
        <span>© 2025 300 Elektra Entertainment</span>
      </div>
    </div>
  );
};

export default Copyright;
