export const MyAssets = {
  BG_DESKTOP_MP4:
    "https://github.com/kevin700brands/ngeeyl-assets/raw/refs/heads/main/videos/bg.desktop.mp4",
  BG_DESKTOP2_MP4:
    "https://github.com/kevin700brands/ngeeyl-assets/raw/refs/heads/main/videos/bg.desktop2.mp4",
  BG_MOBILE2_MP4:
    "https://github.com/kevin700brands/ngeeyl-assets/raw/refs/heads/main/videos/bg.mobile2.mp4",
  BG_MOBILE_GIF:
    "https://github.com/kevin700brands/ngeeyl-assets/raw/refs/heads/main/videos/bg.mobile.gif",
};
