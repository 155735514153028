import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Variants } from "framer-motion";
import { data } from "./SocialsData";
import styled from "styled-components";

const variant: Variants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

interface SocialsProps {
  isActive?: boolean;
}

export default function Socials({ isActive }: SocialsProps) {
  const socialItems = data.map((item, idx) => (
    <li
      key={idx}
      className="text-white w-6 h-6 md:w-16 md:h-16 text-center flex justify-center items-center hover:text-primary transition-all"
    >
      <a href={item.to} rel="noreferrer" target="_blank" data-track={item.name}>
        <FontAwesomeIcon icon={item.icon as any} />
      </a>
    </li>
  ));

  return (
    <motion.section
      initial="hidden"
      variants={variant}
      animate={isActive ? "visible" : "hidden"}
      id="socials"
    >
      <ul className="text-black flex flex-row justify-center items-center text-lg md:text-3xl">
        {socialItems}
      </ul>
    </motion.section>
  );
}
