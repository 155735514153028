import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";

export default function App() {
  return (
    <>
      <Router>
        {/* <Preloader /> */}
        {/* <Navigation /> */}
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
      </Router>
    </>
  );
}
