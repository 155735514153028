import { motion } from "framer-motion";
import styled from "styled-components";
import { ArrowTypes } from "./types";

export const MusicDetail = styled(motion.div)`
  width: 400px;
  @media (max-width: 1368px) {
    width: 300px;
  }
`;

export const MusicImage = styled(motion.a)`
  transition: all 0.3s ease;
  overflow: hidden;
  width: 400px;
  text-align: right;

  :hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  @media (max-width: 1368px) {
    width: 300px;
  }
`;

export const Arrow = styled.button<ArrowTypes>`
  display: block;
  position: absolute;
  top: 110%;
  right: ${(props) => props.right && "47%"};
  left: ${(props) => props.left && "47%"};
  z-index: 2;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;

  :focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    right: ${(props) => (props.right ? props.right : "inherit")};
    left: ${(props) => (props.left ? props.left : "inherit")};
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SlideItem = styled.div`
  max-width: 350px;
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    max-width: 800px;
    height: 70vh;
    /* max-width: 700px; */
  }

  @media (min-width: 1024px) {
    max-width: 800px;
  }
`;
