import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import LOGO_GIF from "../../assets/images/logo.gif";
import { PageKey } from "../../features/Global/Global.slice";
import { RootState } from "../../Store";
import useGlobalService from "../Hooks/useGlobalService";
import { FadeVariant } from "../../components/FramerMotion/Fade";
import { Socials } from "../../components";
import { isAndroid } from "../../constants";
import Copyright from "../../components/Copyright/Copyright";
import styled from "styled-components";
import { RoutePattern } from "../../routes/RoutePattern";
import BackgroundVideo from "../../components/BackgroundVideo/BackgroundVideo";
// import VIDEOMP4 from "../../assets/videos/bg.desktop2.mp4";
// import VIDEOMP4MOBILE from "../../assets/videos/bg.mobile2.mp4";
// import VIDEOGIF from "../../assets/videos/bg.mobile.gif";
import { useState } from "react";
import { MyAssets } from "../../assets";

const Anchor2 = styled.a`
  font-weight: normal;
`;

interface LayoutProps {
  show: boolean;
}

const Layout2 = ({ show }: LayoutProps) => {
  const { playing } = useSelector((state: RootState) => state.Global);
  const { setKey, setPlaying } = useGlobalService();
  const [played, setPlayed] = useState(false);

  const handleClick = (value: boolean) => {
    setPlaying(value);
    setPlayed(true);
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col overflow-hidden p-2 z-50"
      initial="visible"
      variants={FadeVariant}
      animate={show ? "visible" : "hidden"}
    >
      <BackgroundVideo
        VIDEOMP4={MyAssets.BG_DESKTOP2_MP4}
        VIDEOGIF={MyAssets.BG_MOBILE_GIF}
        VIDEOMP4MOBILE={MyAssets.BG_MOBILE2_MP4}
        played={played}
      />

      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col">
          <img src={LOGO_GIF} alt="logo" className="w-24 md:w-36" />
          <span className="my-2 text-white text-2xl md:text-4xl capitalize">
            {window.location.pathname === RoutePattern.Home
              ? "SSetilian"
              : "Red Light / Escalade"}
          </span>
          <a
            className="text-white text-center py-2 px-5  text-sm md:text-md border-white border-2 hover:bg-white hover:text-black"
            href="https://ngeeyl.lnk.to/ssetilian"
            target="_blank"
            rel="noreferrer"
          >
            Listen Now
          </a>
        </div>
        <div className="flex flex-col items-end  text-white text-lg md:text-4xl uppercase">
          {/* opens a popup music */}
          <button
            role="link"
            data-href="#music"
            className="hover:text-primary"
            onClick={() => setKey(PageKey.MUSIC)}
          >
            MUSIC
          </button>
          {/* opens a popup videos*/}
          {/*<button
            role="link"
            data-href="#videos"
            className="hover:text-primary"
            onClick={() => setKey(PageKey.VIDEOS)}
          >
            VIDEOS
          </button>*/}
          <Anchor2
            className="hover:text-primary"
            href="https://youtube.com/playlist?list=PLhFEVXB_4yfSmDbt2aikY-NGX4b-IhY7O"
            target="_blank"
          >
            VIDEOS
          </Anchor2>
        </div>
      </div>
      <div
        className={`flex flex-col items-center justify-between mt-auto md:mb-0 ${
          !isAndroid ? "mb-28" : "mb-12"
        }`}
      >
        <div className="flex-row flex items-center justify-between w-full">
          <div className="hidden flex-row items-center text-lg md:text-4xl text-white">
            <span className="mr-2">SOUND</span>
            {/* switch for audio */}
            <button
              className={`hover:text-primary ${playing && "text-primary"}`}
              onClickCapture={() => {
                handleClick(true);
              }}
            >
              ON
            </button>
            /
            <button
              className={`hover:text-primary ${!playing && "text-primary"}`}
              onClickCapture={() => {
                handleClick(false);
              }}
            >
              OFF
            </button>
          </div>
          <div className="hidden md:block">
            <Copyright />
          </div>
          <div className="flex justify-center items-center w-full md:w-auto">
            <Socials isActive />
          </div>
        </div>
        <div className="block mt-3 lg:hidden">
          <Copyright />
        </div>
      </div>
    </motion.div>
  );
};

export default Layout2;
